import axios from "axios";

export let headers = {
	"Tenant-Access-Token": "ZDA0NTc0OGVkOGMxM2RlOWRjMDMwNGEyYzg2ZWRhZjk=",
	"Tenant-Access-Key": "r2nXmz0xskSd4g",
};

export const api = axios.create({
	baseURL: "https://tiltlabs-dev.confirmu.com",
	headers,
});

export const BASE_URL = "https://tiltlabs-dev.confirmu.com";
